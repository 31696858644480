/** @jsx jsx */
import { jsx } from 'theme-ui'

const CartSvg = ({ open, itemCount }) => {
  const Empty = (
    <svg
      width="37"
      height="18"
      viewBox="0 0 37 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 302">
        <path
          id="Ellipse 28"
          d="M36.0238 3.38435C36.0239 3.38434 36.0232 3.38582 36.0212 3.38877C36.0227 3.38582 36.0237 3.38435 36.0238 3.38435ZM35.1379 3.05049C35.5255 3.22389 35.7718 3.37956 35.9127 3.5C35.7718 3.62044 35.5255 3.77611 35.1379 3.94951C34.3056 4.32177 33.0388 4.68143 31.3956 4.9923C28.1257 5.61094 23.5659 6 18.5 6C13.4341 6 8.87433 5.61094 5.60442 4.9923C3.96123 4.68143 2.69438 4.32177 1.86213 3.94951C1.47446 3.77611 1.22818 3.62044 1.08726 3.5C1.22818 3.37956 1.47446 3.22389 1.86213 3.05049C2.69438 2.67823 3.96123 2.31857 5.60442 2.0077C8.87433 1.38906 13.4341 1 18.5 1C23.5659 1 28.1257 1.38906 31.3956 2.0077C33.0388 2.31857 34.3056 2.67823 35.1379 3.05049ZM0.976171 3.38435C0.976285 3.38435 0.977264 3.38583 0.978765 3.38878C0.976808 3.38582 0.976057 3.38434 0.976171 3.38435ZM0.976171 3.61565C0.976057 3.61566 0.976808 3.61418 0.978765 3.61122C0.977264 3.61417 0.976285 3.61565 0.976171 3.61565ZM36.0212 3.61122C36.0232 3.61418 36.0239 3.61565 36.0238 3.61565C36.0237 3.61566 36.0227 3.61418 36.0212 3.61122Z"
          strokeWidth="2"
          sx={t => ({
            stroke: open ? t.colors.governorBay : 'secondary',
            fill: open ? t.colors.governorBay : 'secondary'
          })}
        />
        <path
          id="Vector 1"
          d="M36 3.62109C34.7931 8.04638 29.6034 16.897 18.5 16.897C7.39655 16.897 2.2069 8.04638 1 3.62109"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          sx={t => ({
            stroke: open ? t.colors.governorBay : 'secondary'
          })}
        />
      </g>
    </svg>
  )

  const Full = (
    <svg
      width="37"
      height="25"
      viewBox="0 0 32 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.60501 5.26285C6.56926 2.16811 11.1236 0 16.4284 0C21.8552 0 26.4966 2.26898 28.3832 5.47782C29.6318 5.77643 30.5921 6.12118 31.1832 6.49658C31.2085 6.50136 31.2338 6.50713 31.2591 6.51395C31.7924 6.65763 32.1082 7.2064 31.9645 7.73967C30.8715 11.7966 26.1611 19.9974 15.9999 19.9974C5.83862 19.9974 1.12822 11.7966 0.0351698 7.73967C-0.108508 7.2064 0.207317 6.65763 0.740585 6.51395C0.765721 6.50718 0.790891 6.50143 0.816052 6.49667C1.55475 6.02742 2.87037 5.60607 4.60501 5.26285ZM15.9997 10.411C21.6348 10.411 26.5833 9.84805 29.3955 8.99981C27.7345 12.7794 23.5757 17.9974 15.9999 17.9974C8.42406 17.9974 4.2653 12.7796 2.60422 8.99994C5.4165 9.84811 10.3648 10.411 15.9997 10.411Z"
        sx={t => ({
          fill: open ? t.colors.governorBay : 'secondary'
        })}
      />
    </svg>
  )

  return itemCount ? Full : Empty
}

export default CartSvg
