/** @jsx jsx */
import { Link } from 'gatsby'
import { jsx } from 'theme-ui'
import PropTypes from 'prop-types'
import { useAnalytics } from '@chordcommerce/gatsby-theme-autonomy'
import { useLocation } from '@reach/router'
import { toURL } from '~/utils/intl/localePrefix'

const ProductLink = ({ slug, children, sx, ...props }) => {
  const { trackClickProduct } = useAnalytics()
  const location = useLocation()

  const url = toURL(location && location.pathname, `/products/${slug}/`)

  return (
    <Link
      to={url}
      onClick={() => trackClickProduct(slug)}
      sx={{
        ...sx,
      }}
      {...props}
    >
      {children}
    </Link>
  )
}

ProductLink.propTypes = {
  slug: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default ProductLink
