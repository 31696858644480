/** @jsx jsx */
import { Flex, jsx, Link } from 'theme-ui'
import FacebookIcon from '~/assets/images/icons/facebook.svg'
import InstagramIcon from '~/assets/images/icons/instagram.svg'
import LinkedinIcon from '~/assets/images/icons/linkedin.svg'
import PintrestIcon from '~/assets/images/icons/pintrest.svg'
import SpotifyIcon from '~/assets/images/icons/spotify.svg'
import TiktokIcon from '~/assets/images/icons/tiktok.svg'
import TwitterIcon from '~/assets/images/icons/twitter.svg'

const FooterSocialButton = ({ children, ...props }) => {
  return (
    <Link
      {...props}
      sx={{
        borderRadius: '100%',
        height: '33px',
        width: '33px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '10px',
        ':last-child': {
          marginRight: '0',
        },
        '& > svg': {
          height: '18px',
        },
      }}
    >
      {children}
    </Link>
  )
}

const FooterSocial = ({ social }) => {
  return (
    <Flex
      sx={{
        borderBottom: ['1px solid', 'none'],
        paddingBottom: ['1.5rem', '0'],
        justifyContent: ['center', null, 'left'],
        marginBottom: ['1.5rem', null, '0'],
      }}
    >
      <FooterSocialButton
        href={`https://www.instagram.com/${social.instagramUsername}`}
        target="_blank"
        aria-label="Instagram"
        rel="noreferrer"
      >
        <InstagramIcon />
      </FooterSocialButton>
      <FooterSocialButton
        href={`https://www.facebook.com/profile.php?id=${social.facebookUsername}`}
        target="_blank"
        aria-label="Facebook"
        rel="noreferrer"
      >
        <FacebookIcon />
      </FooterSocialButton>
      <FooterSocialButton
        href={`https://www.twitter.com/${social.twitterUsername}`}
        target="_blank"
        aria-label="Pinterest"
        rel="noreferrer"
      >
        <TwitterIcon />
      </FooterSocialButton>
      <FooterSocialButton
        href={`https://www.tiktok.com/${social.tiktokUsername}`}
        target="_blank"
        aria-label="Pinterest"
        rel="noreferrer"
      >
        <TiktokIcon />
      </FooterSocialButton>
      <FooterSocialButton
        href={`https://www.pinterest.com/${social.pinterestUsername}`}
        target="_blank"
        aria-label="Instagram"
        rel="noreferrer"
      >
        <PintrestIcon />
      </FooterSocialButton>
      <FooterSocialButton
        href={`https://open.spotify.com/user/${social.spotifyUsername}`}
        target="_blank"
        aria-label="Instagram"
        rel="noreferrer"
      >
        <SpotifyIcon />
      </FooterSocialButton>
      <FooterSocialButton
        href={`https://www.linkedin.com/companies/${social.linkedinUsername}`}
        target="_blank"
        aria-label="Instagram"
        rel="noreferrer"
      >
        <LinkedinIcon />
      </FooterSocialButton>
    </Flex>
  )
}

export default FooterSocial
