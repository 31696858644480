import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import NavBar from '~/components/Nav/Bar'
import useNavigationQuery from '~/hooks/graphql/queries/use-navigation'
import { localeFromPathname } from '~/utils/intl/localePrefix'
import { useLocation } from '@reach/router'

export const Nav = props => {
  const location = useLocation()
  const lineItems = useSelector(
    state => state.cart.data.lineItems,
    shallowEqual
  )

  const itemCount =
    lineItems && lineItems.reduce((acc, item) => acc + item.quantity, 0)

  const { pages, tabs } = useNavigationQuery(
    localeFromPathname(location.pathname).code
  )

  return <NavBar itemCount={itemCount} tabs={tabs} {...props} />
}

export default Nav
