/** @jsx jsx */
import { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { Grid, jsx } from 'theme-ui'
import { toURL } from '~/utils/intl/localePrefix'

const NavMenuCartLink = ({ children, itemCount }) => {
  //setting to true, bc otherwise will rerender on hover
  //in which there seems to be a cycle where the false state persists and causes a flash.
  //By setting to true, we prevent visual glitches with the cart
  //and ensure no degradation in trust to complete transactions
  const [showItemCount, setShowItemCount] = useState(true)
  useEffect(() => {
    setShowItemCount(typeof itemCount === 'number')
  }, [itemCount])

  return (
    <Link
      to={toURL(`/cart`)}
      sx={{
        color: '#ffdd00',
        opacity: showItemCount ? 1 : 0,
        variant: 'text.navLink',
        textAlign: 'center',

        pt: '3px',
        position: 'relative',
        '& div': {
          position: 'absolute',
          right: 0,

          top: 0,
          transform: 'translate(50%, -62%)',
          display: 'inline-grid',
          height: '1.75rem',
          width: '1.75rem',
          boxSizing: 'border-box',
          border: '0px solid',
          backgroundColor: '#2B38BD',
          borderRadius: '100%',
          borderColor: 'inherit',
          flexShrink: 0,
          fontFamily: 'Pitch',
          textAlign: 'center',
          lineHeight: '26px',
          paddingLeft: '1px',
        },
      }}
    >
      {children}
      <Grid
        sx={{
          opacity: showItemCount ? 1 : 0,
          placeItems: 'center',
          placeContent: 'center',
        }}
      >
        {itemCount || 0}
      </Grid>
    </Link>
  )
}

NavMenuCartLink.propTypes = {
  itemCount: PropTypes.number,
}

export default NavMenuCartLink
