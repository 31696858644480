/** @jsx jsx */
import { Box, Text, jsx } from 'theme-ui'
import { openConsentManager } from '@segment/consent-manager'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'

const FooterDataConsent = () => {
  const translate = useTranslate()

  return (
    <Box onClick={openConsentManager} sx={{ cursor: 'pointer' }}>
      <Text sx={{ fontSize: 14 }}>{translate('footer.dataconsent.title')}</Text>
    </Box>
  )
}

export default FooterDataConsent
