/** @jsx jsx */
import PropTypes from 'prop-types'
import { Box, jsx } from 'theme-ui'
import { LinkWrapper } from '~/components/Product/Page/LinkWrapper'

const NavMenuLink = ({ page, forwardSx = {} }) => {
  return (
    <Box
      sx={{
        display: 'block',
      }}
    >
      <LinkWrapper
        slug={page?.slug}
        contenttype={page.__typename}
        sx={{
          cursor: 'pointer',
          fontSize: ['22px', null, '20px'],
          fontFamily: 'heading',
          ...forwardSx,
        }}
      >
        {page.name}
      </LinkWrapper>
    </Box>
  )
}

NavMenuLink.propTypes = {
  page: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }),
}

export default NavMenuLink
