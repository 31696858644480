/** @jsx jsx */

import PropTypes from 'prop-types'
import {
  Container,
  Flex,
  Text,
  Box,
  jsx,
  Heading,
  Button,
  Close,
} from 'theme-ui'
import { useMeasure } from 'react-use'
import { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { SectionDivider } from './SectionDivider'
import GoodlesLogo from './GoodlesLogo'
import ImgLoader from '~/components/Generic/ImgLoader'
import LocaleSwitcher from './LocaleSwitcher'
import { useLocation } from '@reach/router'
import { toURL } from '~/utils/intl/localePrefix'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import { useTheme } from '@emotion/react'
import NavSectionMobile from './NavSectionMobile'
import SectionPageMap from './SectionPageMap'
import NavSectionDesktop from './NavSectionDesktop'
import NavMenuCartLink from './CartLink'
import CartSvg from './CartSvg'
import { LinkWrapper } from '~/components/Product/Page/LinkWrapper'

const initalFeatureState = { image: null, cta: null }

const NavMenu = ({ tabs, itemCount }) => {
  const theme = useTheme()

  const translate = useTranslate()
  // Manages the open or closed state of the accordion
  const [open, toggle] = useState(false)
  const [sectionSublinks, setSectionSublinks] = useState([])
  const [sectionPages, setSectionPages] = useState([])
  const [sectionMobileLinks, setSectionMobileLinks] = useState([])
  const [feature, setFeature] = useState(initalFeatureState)
  const featureExists = feature.image || feature.cta
  const location = useLocation()
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window
    return {
      width,
      height,
    }
  }

  const [currentFocus, setCurrentFocus] = useState(null)

  const resetLinks = () => {
    setCurrentFocus(null)
    setSectionPages(null)
    setSectionSublinks(null)
    setSectionMobileLinks(null)
    setFeature(initalFeatureState)
    const allTabs = document.querySelectorAll('.tab-dropdown')
    allTabs.forEach(tab => tab.setAttribute('data-open', 'false'))
  }

  const handleNavSectionChange = (tab, ignoreClose = false) => {
    const {
      navSectionSublink: sectionSublinks,
      pages,
      mobilePages,
      navSectionName: sectionIdRaw,
      featuredCallToAction,
      featuredImage,
    } = tab
    const sectionId = sectionIdRaw.replace(/ /g, '')
    let previous = document.querySelector(`[data-open=true]`)
    let el = document.querySelector(`[data-navsection=${sectionId}]`)
    let status = el.getAttribute('data-open')
    // let carrot = el.querySelector('svg')

    // If open will not close on new tab click
    if (previous != null) {
      previous.setAttribute('data-open', 'false')
      el.setAttribute('data-open', 'true')
      document.body.style.overflow = 'hidden'
    }

    // Skip for mobile
    if (!ignoreClose) {
      if (status === 'true') {
        el.setAttribute('data-open', 'false')
        toggle(!open)
      } else {
        el.setAttribute('data-open', 'true')
        toggle(true)
      }
    }

    setSectionPages(pages)
    setSectionSublinks(sectionSublinks)
    setSectionMobileLinks(mobilePages ?? pages)
    setFeature({
      image: featuredImage,
      cta: featuredCallToAction,
    })

    if (currentFocus === tab.id) {
      resetLinks()
      return
    }
    setCurrentFocus(tab.id)
  }
  const openMobileNav = () => {
    resetLinks()
    toggle(!open)
  }

  const [prevScroll, setPrevScroll] = useState(0)

  const lockScroll = scrollY => {
    const body = document.querySelector('body')
    body.style.overflow = 'hidden'
    // Mobile IOS workaround
    if (window.matchMedia('(max-width: 768px)').matches) {
      body.style.position = 'fixed'
      body.style.top = `-${scrollY}px`
      setPrevScroll(scrollY)
    }
  }

  const unlockScroll = () => {
    const body = document.querySelector('body')

    body.style.overflow = 'unset'
    body.style.position = 'static'
    // Mobile IOS workaround
    if (window.matchMedia('(max-width: 768px)').matches) {
      body.style.removeProperty('top')
      window.scrollTo(0, prevScroll)
    }
  }

  const defaultHeight = '0px'

  // The height of the content inside of the accordion
  const [contentHeight, setContentHeight] = useState(defaultHeight)
  const colorArray = ['#2B38BD', '#007300', '#6027B4', '#F752AD']
  // Gets the height of the element (ref)
  const [ref, { height }] = useMeasure()

  const [mobileMenuEnable, setMobileMenuEnable] = useState(
    typeof window === 'undefined'
      ? false
      : window.matchMedia('(max-width: 768px)').matches,
  )

  useEffect(() => {
    if (open === false) resetLinks()

    if (open) {
      lockScroll(window.scrollY)
    } else {
      unlockScroll()
    }
  }, [open, mobileMenuEnable])

  useEffect(() => {
    setContentHeight(height * 1.1) //[DQA-5] This should not be how it works. Should not need to multiply. Shimming now for the sake of time
  }, [height])

  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }

    const mq = window.matchMedia('(max-width: 768px)')

    mq.addEventListener('change', onMqChange)
    setMobileMenuEnable(mq.matches)
    return () => {
      mq.removeEventListener('change', onMqChange)
    }
  }, [])

  const onMqChange = mq => {
    if (!mq.matches) {
      toggle(false)
    }
    setMobileMenuEnable(mq.matches)
  }

  return (
    <Container
      id="navbar"
      variant="fullWidth"
      sx={{
        color: open ? 'governorBay' : 'secondary',
        'a:hover, .tab-dropdown:hover': {
          color: open ? 'japaneseLaurel' : 'governorBay',
        },
        '.tab-dropdown:hover': {
          svg: {
            path: {
              fill: open ? 'japaneseLaurel' : 'governorBay',
            },
          },
        },
        backgroundColor: 'transparent',
        fontFamily: 'monospace',
        alignItems: 'center',
        maxWidth: '100vw',
        left: 0,
        position: 'relative',
        display: 'flex',
        px: ['24px', 0],
        transition: 'height .25s ease, box-shadow .25s ease, color .15s ease',
        height: ['72px', '80px'],
        zIndex: 60,
        justifyContent: ['space-between', 'center'],
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          paddingTop: open ? [72, 80] : 0,
          width: '100%',
          left: 0,
          top: 0,

          bg: t => t.colors.mayaBlue,
          transition: '500ms ease,padding 500ms ease',
          overflow: 'visible',
          minHeight: open ? ['100vh', 0] : 0,
          height: open
            ? [`${contentHeight + 130}px`, `${contentHeight + 138}px`]
            : defaultHeight,
        }}
        onClick={e => {
          if (
            e.target.classList.contains('.localeSwitcher') ||
            e.target.closest('.localeSwitcher') ||
            e.target.classList.contains('.tab-dropdown') ||
            e.target.closest('.tab-dropdown')
          ) {
            return
          }

          toggle(false)
        }}
      >
        <div
          sx={{
            overflow: 'hidden',
            height: '100%',
            zIndex: 5,
          }}
        >
          <div ref={ref} sx={{ pt: [0, '60px'] }}>
            <NavSectionMobile
              tabs={tabs}
              handleNavSectionChange={handleNavSectionChange}
              currentFocus={currentFocus}
              sectionMobileLinks={sectionMobileLinks}
              open={open}
            />
            <Flex
              sx={{
                display: ['none', 'flex'],
                justifyContent: [featureExists ? 'space-between' : 'center'],
              }}
            >
              <Flex
                sx={{
                  width: ['100%', null, '70%'],
                  justifyContent: [
                    'space-evenly',
                    featureExists ? 'start' : 'center',
                  ],
                  height: '100%',
                  margin: 'auto',
                  px: 70,
                  flexWrap: ['wrap', 'unset'],
                }}
              >
                {sectionPages &&
                  sectionPages.length &&
                  sectionPages.map((page, i) => (
                    <LinkWrapper
                      key={page.id}
                      slug={page?.slug}
                      contenttype={page.__typename}
                      className="navItem"
                      sx={{
                        textAlign: 'center',
                        position: 'relative',
                        color: colorArray[i],
                        minWidth: 150,
                        width: ['35%', 300],
                        margin: ['unset', '1.2%'],
                        bottom: i % 2 && '50px',
                        marginBottom: [60, 0],
                        '&:hover': {
                          '.gatsby-image-wrapper': {
                            overflow: 'visible !important',
                          },
                          img: {
                            transform: 'scale(1.3) translateY(-10%)',
                          },
                        },
                      }}
                    >
                      <div
                        className="circle"
                        sx={{
                          background: colorArray[i],
                          borderRadius: '50%',
                        }}
                      >
                        {(page.mainImage?.fluid ?? false) && (
                          <ImgLoader image={page.mainImage} />
                        )}
                      </div>
                      <Heading
                        variant="text.h4"
                        sx={{
                          marginTop: 30,
                          maxWidth: 300,
                        }}
                      >
                        {page.name}
                      </Heading>
                    </LinkWrapper>
                  ))}
                {sectionSublinks &&
                  sectionSublinks.map(section => {
                    return (
                      <Flex
                        key={section.id}
                        sx={{
                          flexDirection: 'column',
                          height: 'fit-content',
                          gap: '32px',
                          width: ['100%', '50%'],
                        }}
                      >
                        <Text
                          sx={{
                            fontSize: '14px',
                            fontFamily: 'secondary',
                            textTransform: 'uppercase',
                          }}
                        >
                          {section.navSectionName}
                        </Text>
                        <SectionPageMap
                          pages={section.pages}
                          cta={section.featuredCallToAction}
                        ></SectionPageMap>
                      </Flex>
                    )
                  })}
                <Link
                  to={toURL(location.pathname, '/account')}
                  sx={{
                    width: '100%',
                    display: !mobileMenuEnable ? 'none' : 'block',
                    minWidth: '125px',
                  }}
                >
                  <Button
                    sx={{
                      width: '100%',
                      bg: 'governorBay',
                      color: 'governorBay',
                      '&:hover': {
                        bg: '#FF5947',
                      },
                    }}
                  >
                    {translate('login.sign_in')}
                  </Button>
                </Link>
              </Flex>
              {featureExists && (
                <LinkWrapper
                  slug={feature.cta.page?.slug}
                  contenttype={feature.cta?.page?.__typename}
                >
                  <Flex
                    slug={feature.cta.page?.slug}
                    contenttype={feature.cta?.page?.__typename}
                    sx={{
                      display: ['none', null, 'flex'],
                      width: '100%',
                      pr: '60px',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    {feature.image && (
                      <ImgLoader
                        forwardSx={{
                          width: '300px',
                          height: '224px',
                          borderRadius: '3.2rem',
                          overflow: 'hidden',
                        }}
                        image={feature.image}
                      ></ImgLoader>
                    )}
                    {feature.cta && (
                      <Text
                        sx={{
                          textTransform: 'center',
                          width: '300px',
                          textAlign: 'center',
                          fontFamily: 'body',
                          fontSize: '16px',
                          textDecoration: 'underline',
                        }}
                      >
                        {feature.cta.text}
                      </Text>
                    )}
                  </Flex>
                </LinkWrapper>
              )}
            </Flex>
            <div
              sx={{
                pointerEvents: open ? 'auto' : 'none',
                position: 'absolute',
                bottom: 0,
                transform: 'translateY(100%)',
                width: '100%',
                overflow: 'hidden',
                '& svg': {
                  transformOrigin: 'top left',
                  transition:
                    'transform 1500ms ease-out 500ms,padding 500ms ease',
                  transform: `scale3d(1,${open ? 0.5 : 0},1) `,
                },
              }}
            >
              <SectionDivider fill={theme.colors.mayaBlue}></SectionDivider>
            </div>
          </div>
        </div>
      </Box>
      {/* menu control */}
      <Box sx={{ display: ['block', 'none'], zIndex: '500', width: '45px' }}>
        <Text onClick={() => openMobileNav()}>
          {open ? (
            <Close color={'governorBay'} sx={{ marginLeft: '-8px' }} />
          ) : (
            <svg
              sx={t => ({
                fill: open ? t.colors.governorBay : 'secondary',
                marginLeft: '-13px',
              })}
              width="45px"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
            >
              <title>Icon - Menu</title>
              <path d="M16 17.5h16v2H16zM16 22.8h16v2H16zM16 28.5h16v2H16z" />
            </svg>
          )}
        </Text>
      </Box>
      {/* end menu control */}
      <Link
        to={toURL(location.pathname, '/')}
        sx={{
          padding: ['0 1rem', '0 0 0 2rem'],
          zIndex: 10,
          height: '32px',
          '& svg': { height: '32px' },
        }}
        aria-label="Home"
      >
        <GoodlesLogo
          backColor={open ? 'grape' : 'override.logo.logoBack'}
          frontColor={open ? '#CBC2FF' : 'override.logo.logoFront'}
        />
      </Link>
      <NavSectionDesktop
        tabs={tabs}
        open={open}
        currentFocus={currentFocus}
        handleNavSectionChange={handleNavSectionChange}
      />

      <Flex
        sx={{
          justifyContent: ['center', 'flex-end'],
          width: ['fit-content'],
          pr: [null, '2.4rem', '5.5rem'],
          gap: '2.4rem',
          zIndex: 10,
        }}
      >
        <Flex
          sx={{
            display: ['none', 'block'],
            width: '32px',
            justifyContent: 'center',
          }}
        >
          <LocaleSwitcher
            border={open ? theme.colors.governorBay : theme.colors.secondary}
          />
        </Flex>
        <Flex
          sx={{
            display: [mobileMenuEnable ? 'none' : 'block'],
            '@media(max-width: 768px)': {
              display: 'none',
            },
            width: '32px',
            justifyContent: 'center',
          }}
        >
          <Link
            to={toURL(location.pathname, '/account')}
            sx={{
              textAlign: 'center',
              width: '16px',
              zIndex: 10,
            }}
          >
            <svg
              width="21"
              height="24"
              viewBox="0 0 21 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.3758 10.4256C8.00413 10.4256 6.10191 8.52878 6.10191 6.21281H4.10191C4.10191 9.65526 6.92159 12.4256 10.3758 12.4256V10.4256ZM6.10191 6.21281C6.10191 3.89684 8.00413 2 10.3758 2V0C6.92159 0 4.10191 2.77037 4.10191 6.21281H6.10191ZM10.3758 2C12.7475 2 14.6497 3.89684 14.6497 6.21281H16.6497C16.6497 2.77037 13.83 0 10.3758 0V2ZM14.6497 6.21281C14.6497 8.52878 12.7475 10.4256 10.3758 10.4256V12.4256C13.83 12.4256 16.6497 9.65526 16.6497 6.21281H14.6497ZM18.7516 21.8507C18.7516 21.6147 18.9397 21.4299 19.1697 21.4299V23.4299C20.0432 23.4299 20.7516 22.7204 20.7516 21.8507H18.7516ZM19.1697 21.4299H1.58189V23.4299H19.1697V21.4299ZM1.58189 21.4299C1.80745 21.4299 2 21.6091 2 21.8507H0C0 22.733 0.722288 23.4299 1.58189 23.4299V21.4299ZM2 21.8507C2 17.2961 5.73929 13.5835 10.3758 13.5835V11.5835C4.65675 11.5835 0 16.1697 0 21.8507H2ZM10.3758 13.5835C15.0129 13.5835 18.7516 17.2961 18.7516 21.8507H20.7516C20.7516 16.1697 16.0955 11.5835 10.3758 11.5835V13.5835Z"
                sx={{ fill: open ? 'governorBay' : 'secondary' }}
              />
            </svg>
          </Link>
        </Flex>
        <Flex sx={{ width: '32px', justifyContent: 'center' }}>
          <NavMenuCartLink itemCount={itemCount}>
            <CartSvg open={open} itemCount={itemCount} />
          </NavMenuCartLink>
        </Flex>
      </Flex>
      {/* Background Overlay */}
      <Box
        onClick={() => toggle(false)}
        sx={{
          position: 'fixed',
          top: 0,
          bottom: 0,
          left: 0,
          bottom: '0',
          width: '100%',
          height: '100vh',
          '@supports (height:100dvh)': {
            height: '100dvh',
          },
          background: t => t.colors.secondary,
          opacity: open ? '0.8' : '0',
          pointerEvents: open ? 'auto' : 'none',
          transition: 'opacity 500ms',
          zIndex: '-1',
        }}
      ></Box>
    </Container>
  )
}

NavMenu.propTypes = {
  pages: PropTypes.array,
  itemCount: PropTypes.number,
}
export default NavMenu
