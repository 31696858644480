import { useEffect } from 'react'

const YOTPO_APP_KEY = process.env.GATSBY_YOTPO_APP_KEY
const YOTPO_SCRIPTS = {
  src: `https://staticw2.yotpo.com/${YOTPO_APP_KEY}/widget.js`,
  type: 'text/javascript',
  async: true,
  defer: false,
}

const YotpoScript = () => {
  useEffect(() => {
    if (typeof window.yotpo !== 'object') {
      let r = document.createElement('script')

      r.setAttribute('defer', YOTPO_SCRIPTS.defer)
      r.setAttribute('async', YOTPO_SCRIPTS.async)
      r.src = YOTPO_SCRIPTS.src

      document.head.appendChild(r)
    }
  }, [])

  return null
}

export default YotpoScript
