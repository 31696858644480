/** @jsx jsx */

import { Flex, Box, jsx, Button } from 'theme-ui'
import { Link } from 'gatsby'
import NavMenuLink from './Link'
import NavTabDropdown from './NavTabDropdown'
import SectionPageMap from './SectionPageMap'
import Sublink from './Sublink'

const NavSectionMobile = ({
  tabs,
  handleNavSectionChange,
  currentFocus,
  sectionMobileLinks,
  open,
}) => {
  return (
    <Box
      sx={{
        display: open ? ['flex', 'none'] : 'none',
        position: 'relative',
        top: 0,
        overflowY: 'auto',
        height: 'fit-content',
        maxHeight: 'calc(100vh - 150px)',
        '@supports (height: 100dvh)': {
          maxHeight: 'calc(100dvh - 150px)',
        },
      }}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          width: '100%',
          gap: '32px',
          px: '24px',
        }}
      >
        {tabs.map(tab => {
          if (/contentfulpage/i.test(tab.__typename)) {
            return <NavMenuLink key={tab.id} page={tab} />
          }
          if (/contentfulcollection/i.test(tab.__typename)) {
            return <NavMenuLink page={tab} key={tab.id} />
          }
          if (/contentfulnavsection/i.test(tab.__typename)) {
            return (
              <Flex key={tab.id} sx={{ flexDirection: 'column', gap: '24px' }}>
                <NavTabDropdown
                  key={tab.id}
                  open={open}
                  tab={tab}
                  currentFocus={currentFocus}
                  handleNavSectionChange={() =>
                    handleNavSectionChange(tab, true)
                  }
                >
                  {tab.navSectionName}
                </NavTabDropdown>
                {sectionMobileLinks && currentFocus === tab.id && (
                  <SectionPageMap
                    pages={sectionMobileLinks}
                    cta={tab.mobileTabCta}
                  />
                )}
              </Flex>
            )
          }

          return null
        })}
        <Flex
          sx={{ width: '100%', alignItems: 'center', flexDirection: 'column' }}
        >
          <Button
            as={Link}
            to={'/shop/'}
            sx={t => ({
              mb: '24px',
              mx: '0',
              width: '100%',
              backgroundColor: 'schoolBusYellow',

              color: 'governorBay',

              '&:hover': {
                backgroundColor: 'governorBay',
                color: `${t.colors.schoolBusYellow} !important`,
              },
            })}
          >
            Shop All
          </Button>
          <Sublink
            slug={'/account'}
            contenttype={'ContentfulPage'}
            forwardSx={{
              textAlign: 'center',
              width: 'fit-content',
              textDecoration: 'underline',
              fontFamily: 'heading',
              fontSize: '22px',
            }}
          >
            Account
          </Sublink>
        </Flex>
      </Flex>
    </Box>
  )
}

export default NavSectionMobile
