/** @jsx jsx */
import { Text, Box, jsx } from 'theme-ui'
import { LinkWrapper } from '~/components/Product/Page/LinkWrapper'

const Sublink = ({
  children,
  secondary = false,
  slug,
  tagLine,
  contenttype,
  forwardSx = {},
}) => {
  return (
    <LinkWrapper slug={slug} contenttype={contenttype}>
      <div
        sx={{
          display: 'flex',
          alignItems: 'center',
          fontFamily: ['body', 'heading'],
          cursor: 'pointer',
          fontSize: ['18px', null, '20px'],
          gap: '8px',
          textDecoration: secondary && [null, 'underline'],
          ...forwardSx,
        }}
      >
        <Box
          sx={{
            position: 'relative',
            overflow: 'hidden',
            isolation: 'isolate',
          }}
        >
          <Text>{children}</Text>
        </Box>

        {tagLine && (
          <Text
            sx={{
              padding: '4px 8px',
              fontSize: '12px',
              fontFamily: 'body',
              background: tagLine.color,
              borderRadius: '8px',
            }}
          >
            {tagLine.title}
          </Text>
        )}
      </div>
    </LinkWrapper>
  )
}

export default Sublink
