/**@jsx jsx */
import { Box, jsx } from 'theme-ui'


const Spinner = ({ size = 50, color, circle, type }) => {
  const images = [
    '/images/noodle-circle.png',
    '/images/shell-circle.png',
    '/images/spiral-circle.png'
  ]
  const getNoodle = type => {
    switch (type) {
      case 'cheddy-mac':
      case 'mover-shaker':
        return images[0]

      case 'shella-good':
        return images[1]

      case 'twist-parm':
        return images[2]

      default:
        return images[Math.floor(Math.random() * images.length)]
    }
  }

  const scale = Math.min(200, Math.max(0, size))
  const normal = (scale - 0) / (200 - 0)
  const noodle = getNoodle(type)

  return (
    <Box
      sx={{
        '@keyframes spin': {
          from: {
            transform: 'rotate(0deg)'
          },
          to: {
            transform: 'rotate(360deg)'
          }
        },
        position: 'relative',
        height: `calc(200px * ${normal} )`,
        width: `calc(200px * ${normal} )`,
        animation: '1.3s infinite spin',
        animationTimingFunction: 'linear'
      }}
    >
      {color && circle && (
        <div
          sx={{
            opacity: 0.3,
            filter: 'hue-rotate(3.142rad) ',
            height: `calc(200px * ${normal} )`,
            width: `calc(200px * ${normal} )`,
            border: `calc(200px * ${normal} / 6.5) solid`,
            borderColor: color,
            borderRadius: '50%'
          }}
        />
      )}
      <Box
        sx={{
          height: `calc(200px * ${normal} )`,
          width: `calc(200px * ${normal} )`,

          img: {
            transform: 'rotate(163deg)',
            position: 'absolute',
            top: '3%',
            left: 0,
            width: `65%`
          }
        }}
      >
        <div
          sx={{
            backgroundImage: `url("${noodle}")`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            maskImage: `url("${noodle}")`,
            filter: color && 'contrast(3) grayscale(10%)',
            maskSize: 'contain',
            maskRepeat: 'no-repeat',
            height: `30%`,
            width: `60%`,
            position: 'absolute',
            top: 0,
            transform: 'rotate(160deg)',
            ':after': {
              content: '""',
              position: 'absolute',
              height: '200px',
              width: '200px',
              top: 0,
              left: 0,
              mixBlendMode: 'hard-light',
              background: color,
              opacity: 0.8
            }
          }}
        >
        </div>
      </Box>
    </Box>
  )
}

export default Spinner
