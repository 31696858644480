/** @jsx jsx */
import { Box, Container, Flex, jsx } from 'theme-ui'
import FooterLegal from '~/components/Footer/Legal'
import FooterLinks from '~/components/Footer/Links'
import FooterNewsletterSignup from '~/components/Footer/NewsletterSignup'
import FooterSocial from '~/components/Footer/Social'
import useFooterQuery from '~/hooks/graphql/queries/use-footer'
import GoodlesLogo from '../Nav/NavMenu/GoodlesLogo'
import LocaleSwitcher from '../Nav/NavMenu/LocaleSwitcher'
import FooterDataConsent from './DataConsent'

export const Footer = () => {
  const { catalog, links, legal, social } = useFooterQuery()

  return (
    <Box
      as="footer"
      sx={{
        padding: ['2.5rem 0 4rem 0', null, '4.5rem 0'],
        backgroundColor: 'override.footerBackground',
        color: 'override.footerColor',
      }}
    >
      <Container>
        <GoodlesLogo
          frontColor="override.logo.altLogoFront"
          backColor="override.logo.altLogoBack"
        />
        <Flex
          sx={{
            paddingBottom: [null, null, 200],
            flexDirection: ['column', null, 'row'],
          }}
        >
          <FooterLinks links={links.tabs ?? []} catalog={catalog} />
          <FooterNewsletterSignup
            sx={{
              color: 'override.footerColor',
              '& input': {
                color: 'override.footerColor',
                borderColor: 'override.footerColor',
                background: 'white',
              },
              '& button': {
                color: 'override.footerBackground',
                backgroundColor: 'override.footerColor',
              },
            }}
          />
        </Flex>
        <Flex
          sx={{
            justifyContent: 'space-between',
            flexDirection: ['column', null, 'row'],
            paddingTop: ['1.5rem', null, '0'],
          }}
        >
          <Flex sx={{ justifyContent: 'space-between' }}>
            <FooterLegal links={legal.tabs ?? []} />
            <Box sx={{ display: ['block', 'none'] }}>
              <LocaleSwitcher topRight />
            </Box>
          </Flex>
          <FooterSocial social={social ?? []} />
        </Flex>
        <FooterDataConsent />
      </Container>
    </Box>
  )
}

export default Footer
