/* @jsx jsx */
import { useCart, useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import { Link } from 'gatsby'
import React, { useEffect, useState, useRef } from 'react'
import { jsx, Box, Text, Flex, Grid, Button } from 'theme-ui'
import { intToWord } from '../../../utils/compare/intToWord'
import gsap from 'gsap'
import { toURL } from '~/utils/intl/localePrefix'

export const AddedToCartNotification = () => {
  //#region Hooks
  const clickRef = useRef(null)
  const timelineRef = useRef(gsap.timeline({ paused: true }))
  const { cart } = useCart()
  const translate = useTranslate()
  const [isOpen, setIsOpen] = React.useState(false)
  const [item, setItem] = useState(null)

  useEffect(() => {
    const callback = event => {
      if (event?.detail?.variant?.sku) {
        setItem(event.detail.variant)
        setIsOpen(true)
        timelineRef.current.play()
      }
    }

    document.addEventListener('addToCart', callback)
    buildTimeline()
    return () => {
      document.removeEventListener('addToCart', callback)
    }
  }, [])

  useEffect(() => {
    if (!clickRef.current || !isOpen) return

    window.addEventListener('click', handleClickOutside)

    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [isOpen])

  useEffect(() => {
    let timeout
    if (isOpen) {
      timeout = setTimeout(() => {
        setIsOpen(false)
        timelineRef.current.reverse()
      }, 2500)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [isOpen])
  //#endregion
  //#region Methods
  const handleClickOutside = event => {
    if (
      isOpen &&
      clickRef.current &&
      !clickRef.current.contains(event.target)
    ) {
      setIsOpen(false)
      timelineRef.current.reverse()
    }
  }

  const buildTimeline = () => {
    timelineRef.current.to(
      clickRef.current,
      {
        autoAlpha: 1,
        y: 0,
        ease: 'power4.out',
        duration: 0.5,
      },
      0,
    )

    timelineRef.current.to(
      [
        clickRef.current.querySelector('.atc-product'),
        clickRef.current.querySelector('.cta-btn'),
      ],
      {
        autoAlpha: 1,
        y: 0,
        stagger: 0.2,
        ease: 'power4.out',
        duration: 0.5,
      },
      0,
    )

    timelineRef.current.to(
      clickRef.current.querySelector('.splooge-container > svg'),
      {
        scaleY: 1,
        ease: 'power4.out',
        duration: 0.8,
      },
      0,
    )
  }
  //#endregion
  //#region Derived State
  const itemInCart = !!item
    ? (cart?.data?.lineItems || []).find(
        el => el && el.variant && el.variant.id === item.id,
      )
    : null
  const isSubscription =
    itemInCart && itemInCart.subscriptionLineItems?.length > 0

  const presentationIsSize = !!item?.optionValues.filter(
    el => el.optionTypeName === 'size',
  ).length
  const presentationIsShoeSize = !!item?.optionValues.filter(
    el => el.optionTypeName === 'shoe-size',
  ).length
  //#endregion
  return (
    <Box
      ref={clickRef}
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        opacity: 0,
        transform: 'translateY(-100%)',
        pointerEvents: isOpen ? 'all' : 'none',
        bg: '#F7EFD9',
        zIndex: 999,
        p: 0,
      }}
    >
      <Flex
        sx={{
          height: '84px',
          borderBottomColor: '#FF5A47',
          width: '100%',
          borderBottomWidth: '1px',
          borderBottomStyle: 'solid',
          alignItems: 'center',
          overflow: 'hidden',
        }}
      >
        <Text
          as="p"
          sx={{
            textTransform: 'uppercase',
            color: '#FF5A47',
            fontFamily: 'heading',
            textAlign: 'center',
            mx: 'auto',
            fontSize: ['16px', '20px'],
            lineHeight: ['20px', '24px'],
          }}
        >
          {translate('product.added_to_cart')}
        </Text>
      </Flex>

      <Flex
        className="atc-content"
        sx={{
          position: 'absolute',
          transform: 'translate(-50%, 50%)',
          zIndex: 32,
          mx: 'auto',
          left: '50%',
          width: ['100%', '87.5%'],
          px: ['20px', '0px'],
          maxWidth: '866px',
          justifyContent: 'space-between',
          alignItems: 'center',
          opacity: 1,
        }}
      >
        <Flex
          sx={{
            alignItems: 'flex-start',
            transform: 'translateY(-50px)',
            opacity: 0,
          }}
          className="atc-product"
        >
          <Box sx={{ mr: ['24px', '32px'] }}>
            <img
              src={
                item?.image?.url ||
                (item?.images &&
                  item?.images.length &&
                  item?.images[0]?.smallUrl)
              }
              alt={item?.name}
              sx={{
                borderRadius: '50%',
                border: '1px solid #FFDD00',
                maxWidth: ['56px', '116px'],
                maxHeight: ['56px', '116px'],
                objectFit: 'contain',
              }}
            />
          </Box>
          <Box sx={{ pt: ['0px', '16px'] }}>
            <Text
              as="p"
              sx={{
                fontFamily: 'heading',
                color: '#FF5A47',
                fontSize: ['16px', '20px'],
                lineHeight: '20px',
              }}
            >
              {item?.name}
            </Text>
            <Text
              as="p"
              sx={{
                mt: '12px',
                color: '#FF5A47',
                fontFamily: 'body',
                fontSize: ['14px'],
                lineHeight: '16px',
              }}
            >
              {presentationIsSize
                ? translate('cart.n_pack', {
                    amount: item?.optionValues[0]?.presentation,
                  })
                : presentationIsShoeSize
                ? translate('cart.size', {
                    size: item?.optionValues[0]?.presentation,
                  })
                : item?.optionValues[0]?.presentation}
            </Text>
            <Text
              as="p"
              sx={{
                color: '#FF5A47',
                fontFamily: 'body',
                fontSize: ['14px'],
                lineHeight: '16px',
                mt: '4px',
              }}
            >
              {isSubscription
                ? translate('cart.renew_string', {
                    length: intToWord(
                      itemInCart.subscriptionLineItems[0].intervalLength,
                    ),
                    units: itemInCart.subscriptionLineItems[0].intervalUnits,
                  })
                : translate('cart.one_time_purchase')}
            </Text>
          </Box>
        </Flex>
        <Link
          to={toURL('/cart')}
          className="cta-btn"
          sx={{
            transform: 'translateY(-50px)',
            opacity: 0,
            maxWidth: ['112px', '256px'],
            width: '100%',
            alignSelf: ['flex-end', 'unset'],
          }}
        >
          <Button
            sx={{
              width: '100%',
              fontSize: ['12px', '14px'],
              bg: '#fff',
              color: '#FF5A47',
              textAlign: 'center',
              mx: 'auto',
              p: 0,
              fontFamily: 'heading',
              lineHeight: '16px',
              border: '1px solid #FF5A47',
              '&:hover': {
                bg: '#FF5A47',
                color: '#fff',
              },
            }}
          >
            {translate('product.proceed_to_cart')}
          </Button>
        </Link>
      </Flex>

      <Grid
        className="splooge-container"
        sx={{
          position: 'absolute',
          width: '100%',
          pointerEvents: 'none',
          background: 'transparent',
          top: 0,
          bottom: 0,
          '& svg': {
            transformOrigin: 'top left',
            transform: `scale3d(1,0,1) `,
          },
        }}
      >
        <svg
          viewBox="0 0 1440 376"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          sx={{
            width: '100%',
            position: 'absolute',
            top: '100%',
            bottom: 0,
            minHeight: '220px',
            maxHeight: '300px',
            overflow: 'hidden',
          }}
        >
          <path
            d="M-92 300.493C-69.5639 294.665 -34.752 287.774 9.80078 287.45C86.1314 286.895 114.556 315.895 201.346 318.022C286.3 320.057 304.584 294.156 390.336 292.075C540.762 288.606 583.398 374.356 728.554 375.975C890.557 377.779 956.428 280.558 1101.26 286.987C1207.94 291.705 1242.67 327.134 1333.61 325.7C1383.03 324.914 1418.16 312.194 1441 300.493C1441 223.577 1441 77.0086 1441 0L-92 0L-92 300.493Z"
            fill="#F7EFD9"
          />
        </svg>
      </Grid>
    </Box>
  )
}
