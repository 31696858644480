/** @jsx jsx */
import PropTypes from 'prop-types'
import { Box, jsx } from 'theme-ui'
import NavMenu from '~/components/Nav/NavMenu'

export const NavBar = ({ itemCount, tabs = [] }) => {
  const pagesToLinkTo = tabs ? [...tabs] : []

  return (
    <Box
      as="nav"
      role="navigation"
      sx={{
        backgroundColor: 'transparent',
        overflow: 'visible',
        width: '100%',
        zIndex: 3,
      }}
    >
      <NavMenu tabs={tabs} pages={pagesToLinkTo} itemCount={itemCount} />
    </Box>
  )
}

NavBar.propTypes = {
  itemCount: PropTypes.number,
  pages: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
      }),
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        navSectionName: PropTypes.string.isRequired,
      }),
    ]),
  ),
}

export default NavBar
