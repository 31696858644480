/** @jsx jsx */
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { Flex, Text, jsx } from 'theme-ui'
import { useLocation } from '@reach/router'
import { toURL } from '~/utils/intl/localePrefix'
const FooterLegal = ({ links }) => {
  const location = useLocation()
  return (
    <Flex
      sx={{
        flexDirection: ['row'],
        flexWrap: ['wrap', 'unset'],
        fontSize: '16px',
        lineHeight: '20px',
        justifyContent: ['unset', 'center'],
        paddingBottom: ['1.5rem', '0'],
        gap: '10px',
        textAlign: ['left'],
        whiteSpace: ['unset', 'nowrap'],
      }}
    >
      {links.map(({ name, slug }) => {
        return (
          <Text
            key={slug}
            sx={{
              width: ['50%', '100%'],

              marginRight: 90,
            }}
          >
            <Link to={toURL(location && location.pathname, `/${slug}/`)}>
              {name}
            </Link>
          </Text>
        )
      })}
      {/* <Text sx={{ margin: ['0 0 1rem 0', '0 0 0 0.75rem'] }}>
        © Chord Commerce {new Date().getFullYear()}
      </Text> */}
    </Flex>
  )
}

FooterLegal.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
    }),
  ),
}

export default FooterLegal
