import React, { useState, createContext, useEffect, useMemo } from 'react'
import {
  logSentryError,
  useCart,
  useUser,
} from '@chordcommerce/gatsby-theme-autonomy'
import { StringParam, useQueryParam } from 'use-query-params'

export const ChordCartContext = createContext()

export const CartProvider = ({ children }) => {
  const { cart, loadCart, modifyCart } = useCart()
  const { loadUser } = useUser()
  const [_shareasaleClickId] = useQueryParam('sscid', StringParam)

  useEffect(() => {
    const loadAndTrackCart = async () => {
      try {
        await loadUser()
        await loadCart()

        // Get the shareasale click ID
        const shareasaleClickId =
          _shareasaleClickId || localStorage.getItem('shareasaleClickId')

        // Check if we need to update the cart's metadata to add the shareasale click ID.
        if (
          shareasaleClickId &&
          cart?.data?.metadata?.sscid !== shareasaleClickId
        ) {
          localStorage.setItem('shareasaleClickId', shareasaleClickId)
          await modifyCart({
            metadata: { ...cart?.data?.metadata, sscid: shareasaleClickId },
          })
        }
      } catch (error) {
        logSentryError(error, { source: 'Cart' })
      }
    }

    if (!cart.isFetching) loadAndTrackCart()
  }, [cart?.data?.metadata?.sscid, _shareasaleClickId])

  const amountNeededForFreeShipping = Math.max(
    0,
    process.env.GATSBY_FREE_SHIPPING_THRESHOLD - parseFloat(cart.data.total),
  )
  const { isFetching, data: order } = cart

  if (isFetching && order.lineItems && order.lineItems.length === 0) {
    // Fetch Line Items
  }
  if (!order.lineItems || order.lineItems.length === 0) {
    // continue if loaded
  }

  const [hasDonation, sethasDonation] = useState(false)

  const donationMemo = useMemo(
    () => ({ hasDonation, sethasDonation }),
    [hasDonation, sethasDonation],
  )

  return (
    <ChordCartContext.Provider
      value={{
        ...order,
        donationMemo,
        amountNeededForFreeShipping,
      }}
    >
      {children}
    </ChordCartContext.Provider>
  )
}
