/** @jsx jsx */
import { Box, Flex, jsx, useColorMode } from 'theme-ui'
import useNotificationBar from '~/hooks/components/use-notification-bar'
import { useLocation } from '@reach/router'
import { localeFromPathname } from '~/utils/intl/localePrefix'
import Marquee from 'react-fast-marquee'

const NotificationBar = () => {
  const location = useLocation()
  const { loaded, notification } = useNotificationBar(
    localeFromPathname(location.pathname).code,
  )
  const [colorMode] = useColorMode()

  if (!notification || !loaded) return null
  const isCart = colorMode === 'Cart'

  const repeatMarkdown = markdown => {
    const array = Array(10).fill(markdown)
    return array
  }
  return (
    <Flex
      sx={{
        alignItems: 'center',
        backgroundColor: isCart ? 'accent' : 'secondary',
        minHeight: ['48px', null, '60px'],
        textAlign: 'center',
        justifyContent: 'space-around',
        variant: 'text.link',
        px: 1,
        position: 'relative',
        zIndex: 1,
      }}
    >
      <Marquee speed={30} gradient={false}>
        {repeatMarkdown(notification.description.childMarkdownRemark.html).map(
          (markdown, i) => (
            <Box
              key={`${markdown}-${i}`}
              variant="heading"
              sx={{
                px: '30px',
                color: 'primary',
                fontFamily: 'heading',
                fontSize: ['12px !important', '14px !important'],
                p: {
                  display: 'inline-block',
                  fontSize: ['12px !important', '14px !important'],
                },
              }}
              dangerouslySetInnerHTML={{
                __html: markdown,
              }}
            />
          ),
        )}
      </Marquee>
    </Flex>
  )
}

export default NotificationBar
