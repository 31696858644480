/** @jsx jsx */
import React from 'react'
import { Flex, Input, Button, jsx, Label } from 'theme-ui'
import Spinner from '../../Spinner'

const FormInputWithLabel = React.forwardRef(
  (
    { buttonText = 'Sign Up', name, label, isLoading = false, ...props },
    ref
  ) => {
    return (
      <Flex sx={{ position: 'relative', height: '100%', width: '100%' }}>
        <Input
          sx={{
            fontSize: ['13px', null, '20px'],
            borderWidth: 2,
            height: 63,
            // letterSpacing: '1px',
            width: '100%',
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderColor: 'inherit',
            color: 'inherit',
            // marginBottom: ['0.75rem', null, '0'],
            paddingLeft: [null, null, '1rem'],
            variant: 'text.body',
            '::placeholder': {
              variant: 'text.link'
            },
            ':not(:placeholder-shown)': {
              // paddingTop: ['15px', null, '30px']
            },
            ':not(:placeholder-shown) + label': {
              opacity: '.75'
            }
          }}
          name={name}
          ref={ref}
          {...props}
        />
        <Button
          sx={{
            height: 63,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            backgroundColor: 'transparent',
            color: 'inherit',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {!isLoading && buttonText}
          {isLoading && <Spinner size="15" color="inherit" />}
        </Button>
        <Label
          htmlFor={name}
          sx={{
            position: 'absolute',
            top: ['8px', null, '12px'],
            left: '15px',
            opacity: '0',
            letterspacing: '1px',
            pointerEvents: 'none'
          }}
        >
          {label}
        </Label>
      </Flex>
    )
  }
)
export default FormInputWithLabel
