/** @jsx jsx */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from '@reach/router'
import FLAG_EN_US from '~/assets/images/flags/en-US.svg'
import FLAG_EN_CA from '~/assets/images/flags/en-CA.svg'
import FLAG_FR_CA from '~/assets/images/flags/fr-CA.svg'
// import FLAG_HE_IL from '~/assets/images/flags/he-IL.svg';
import { localeFromPathname, locales, toURL } from '~/utils/intl/localePrefix'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import { navigate } from 'gatsby'
import { jsx, Box } from 'theme-ui'

const flags = {
  'en-US': FLAG_EN_US,
  'en-CA': FLAG_EN_CA,
  'fr-CA': FLAG_FR_CA
  // 'he-IL': FLAG_HE_IL
}

const Flag = ({ locale }) => {
  const FlagSVG = flags[localeFromPathname(locale).code]

  return (
    <FlagSVG
      sx={{
        width: 24,
        height: 24,
        borderRadius: '100%',
        background: 'white',
        verticalAlign: 'top'
      }}
    />
  )
}

const LocaleSwitcher = ({ layout = 'normal', topRight, onUpdate, border }) => {
  const [isOpen, setIsOpen] = useState(false)

  const location = useLocation()
  const translate = useTranslate()

  const currentLocale = localeFromPathname(location.pathname).code

  if (layout === 'normal') {
    return (
      <Box
        sx={t => ({
          '--background': '#F7EFD9',
          '--border': border ?? t.colors.secondary,
          position: 'relative'
        })}
      >
        <button
          type="button"
          sx={{
            cursor: 'pointer',
            transition: 'opacity .25s',
            '&:hover': {
              opacity: 0.8
            },
            transform: 'translateY(-2px)',
            background: 'transparent',
            display: 'block',
            outline: 'none',
            border: 'none',
            svg: {
              border: '2px solid var(--border)',
              width: 28,
              height: 28
            }
          }}
          onClick={() => setIsOpen(!isOpen)}
        >
          <Flag locale={localeFromPathname(location.pathname).code} />
        </button>
        <Box
          sx={{
            transition: '.55s cubic-bezier(.55,0,.1,1)',
            transitionProperty: 'opacity, transform',
            background: 'var(--background)',
            padding: '8px 0',
            border: '2px solid var(--border)',
            position: 'absolute',
            whiteSpace: 'nowrap',
            left: topRight ? '-90%' : '50%',
            bottom: topRight && 'calc(100% + 15px)',
            top: !topRight && 'calc(100% + 8px)',
            pointerEvents: isOpen ? 'all' : 'none',
            opacity: isOpen ? 1 : 0,
            transform: isOpen
              ? 'translateX(-50%)'
              : 'translateX(-50%) translateY(-25px)',
            '&:before': {
              content: '""',
              width: 12,
              height: 12,
              transform: 'translate3d(-50%, 0, 0) rotate(-45deg) ',
              background: 'var(--background)',
              border: '2px solid var(--border)',
              position: 'absolute',
              top: topRight ? '88px' : '-8px',
              left: topRight ? '86%' : '50%'
            },
            '&:after': {
              content: '""',
              width: 30,
              height: 12,
              background: 'var(--background)',
              transform: 'translateX(-50%)',
              position: 'absolute',
              zIndex: 1,
              top: topRight ? '84px' : 0,
              left: topRight ? '86%' : '50%'
            }
          }}
        >
          <ul sx={{ margin: 0, padding: 0, position: 'relative', zIndex: 2 }}>
            {Object.keys(locales).map(key => {
              const isCurrent = key === currentLocale
              return (
                <li key={key} sx={{ display: 'block' }}>
                  <a
                    sx={{
                      padding: '8px 30px 8px 14px',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 12,
                      '&:hover': {
                        background: '#FFDD00'
                      }
                    }}
                    href={toURL(
                      key,
                      location.pathname.replace(`/${currentLocale}`, '')
                    )}
                  >
                    <Flag locale={key} />
                    <span
                      sx={{
                        font: '700 14px/1 Sofia-Bold,system-ui,sans-serif',
                        color: '#007300'
                      }}
                    >
                      {locales[key]}{' '}
                      {currentLocale === key
                        ? `(${translate('locale_switcher.selected')})`
                        : ''}
                    </span>
                  </a>
                </li>
              )
            })}
          </ul>
        </Box>
      </Box>
    )
  } else {
    return (
      <Box className="localeSwitcher" sx={{ marginBottom: 10 }}>
        <Box>
          <label htmlFor="">
            <span>{translate('locale_switcher.label')}</span>
            <Box
              sx={{
                position: 'relative',
                '&:before': {
                  content: '""',
                  width: 0,
                  height: 0,
                  borderStyle: 'solid',
                  borderWidth: '5px 3px 0 3px',
                  borderColor:
                    'currentColor transparent transparent transparent',
                  position: 'absolute',
                  right: 0,
                  top: 14,
                  pointerEvents: 'none'
                }
              }}
            >
              <select
                sx={{
                  appearance: 'none',
                  background: 'transparent',
                  border: 'none',
                  borderBottom: '2px solid currentColor',
                  padding: '8px 10px 8px 0',
                  font: '700 16px/1 Sofia-Bold,system-ui,sans-serif',
                  color: '#6E5BE8',
                  width: '100%'
                }}
                onChange={e => {
                  if (onUpdate) {
                    onUpdate()
                  }
                  const targetUrl =
                    e.target.options[e.target.selectedIndex].value
                  if (e) {
                    navigate(targetUrl)
                  }
                }}
              >
                {Object.keys(locales).map(key => {
                  const isCurrent = key === currentLocale
                  return (
                    <option
                      key={key}
                      value={toURL(
                        key,
                        location.pathname.replace(`/${currentLocale}`, '')
                      )}
                      selected={isCurrent}
                    >
                      {locales[key]}{' '}
                      {isCurrent
                        ? `(${translate('locale_switcher.selected')})`
                        : ''}
                    </option>
                  )
                })}
              </select>
            </Box>
          </label>
        </Box>
      </Box>
    )
  }
}

Flag.propTypes = {
  locale: PropTypes.string
}

LocaleSwitcher.propTypes = {
  layout: PropTypes.string,
  onUpdate: PropTypes.func
}

export default LocaleSwitcher
