/** @jsx jsx */
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Box, Flex, Heading, jsx, Text } from 'theme-ui'
import FormInputWithLabel from '~/components/Generic/Form/InputWithLabel'
import {
  analytics,
  logSentryError,
  useTranslate,
} from '@chordcommerce/gatsby-theme-autonomy'
import { Link } from 'gatsby'
import { toURL } from '../../../utils/intl/localePrefix'

const FooterNewsletterSignup = ({ heading, body, btnText, ...props }) => {
  const translate = useTranslate()
  const { trackEmailCaptured } = analytics
  const { register, handleSubmit, errors } = useForm()
  const [submitted, setSubmitted] = useState(false)

  const onSubmit = async data => {
    const { email } = data

    const payload = {
      email: email,
      placement: {
        page: 'all-pages',
        component: 'footer',
        website: window.location.hostname,
      },
    }

    try {
      const namedFN = () => {
        trackEmailCaptured(payload)
        setSubmitted(true)
      }
      namedFN()
    } catch (err) {
      const logError = () => {
        logSentryError(err, { source: 'FooterNewsletterSignup' })
      }
      logError()
    }
  }

  const isCanada =
    typeof window !== 'undefined' &&
    (window.location.pathname.includes('en-CA') ||
      window.location.pathname.includes('fr-CA'))
  return (
    <Box
      sx={{
        paddingBottom: ['2rem', '2.75rem', '0'],
        borderBottom: ['1px solid', null, 'none'],
        textAlign: ['center', null, 'left'],
        width: ['100%', null, '50%'],
      }}
      {...props}
    >
      <Box sx={{ width: ['100%', null, '70%'] }}>
        <Heading
          sx={{
            marginBottom: ['0.5rem'],
            variant: ['text.h3', null, 'text.h2'],
          }}
        >
          {heading || translate('footer.newsletter_signup.title')}
        </Heading>
        <Text
          sx={{
            marginBottom: ['16px', null, '24px'],
            marginRight: 'auto',
            marginLeft: 'auto',
            maxWidth: [null, '60%', 'none'],
          }}
        >
          {body || translate('footer.newsletter_signup.text')}
        </Text>

        {!submitted && (
          <Flex
            as="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{
              flexDirection: ['column', null, 'row'],
              flexWrap: 'wrap',
              margin: [null, 'auto'],
              width: [null, '295px', 'auto'],
            }}
          >
            <Box
              sx={{
                position: 'relative',
                flexGrow: 1,
              }}
            >
              <FormInputWithLabel
                name="email"
                title={translate('footer.newsletter_signup.input_label')}
                label={translate('footer.newsletter_signup.input_label')}
                placeholder={translate('footer.newsletter_signup.input_label')}
                aria-label={translate('footer.newsletter_signup.input_label')}
                ref={register({
                  required: true,
                })}
              />
            </Box>
            {errors && errors['email'] && (
              <Text
                variant="formError"
                sx={{
                  backgroundColor: 'errorLight',
                  padding: '12px 12px',
                  order: [null, null, 3],
                  marginBottom: ['0.75rem', null, '0'],
                }}
              >
                {errors['email'].type === 'required' &&
                  translate('validation.required_field')}

                {errors['email'].type === 'pattern' &&
                  translate('validation.invalid_email')}
              </Text>
            )}
          </Flex>
        )}

        {submitted && (
          <Box
            sx={{
              margin: [null, 'auto'],
              maxWidth: [null, '295px', 'none'],
              textAlign: ['center', null, 'left'],
            }}
          >
            {translate('footer.newsletter_signup.confirmation')}
          </Box>
        )}
        {isCanada && (
          <Box
            sx={{
              maxWidth: [null, '295px', 'none'],
              textAlign: ['center', null, 'left'],
              mx: 'auto',
            }}
          >
            <Link
              to={toURL(
                window.location && window.location.pathname,
                '/privacy-policy',
              )}
              sx={{ fontFamily: 'heading' }}
            ></Link>{' '}
            <Link
              to={toURL(
                window.location && window.location.pathname,
                '/contact',
              )}
              sx={{ fontFamily: 'heading' }}
            ></Link>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default FooterNewsletterSignup
