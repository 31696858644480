/** @jsx jsx */
import { jsx } from 'theme-ui'
import Nav from '../Nav'
import NotificationBar from '../Notification/Bar'
import { alpha, darken } from '@theme-ui/color'
import { useEffect, useState } from 'react'
const StickyBar = ({ background }) => {
  const [scrolled, setScrolled] = useState(false)

  function handleScroll() {
    if (window.scrollY > 5) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, false)
    return () => window.removeEventListener('scroll', handleScroll, false)
  }, [])

  return (
    <div
      sx={t => ({
        position: 'fixed',
        transition: '.3s ease-in-out',
        top: 0,
        width: '100%',
        zIndex: 999,
        isolation: 'isolate',
      })}
    >
      <NotificationBar />
      <div
        sx={t => ({
          position: 'relative',
          width: '100%',
          transition: '.5s ease-in-out',
          top: 0,
          transform: 'translate3d(0,0,100px)',
          zIndex: 1,
          height: '100%',

          pointerEvents: 'auto',
          ':before': {
            content: "''",
            position: 'absolute',
            pointerEvents: 'none',
            top: 0,
            left: 0,
            width: '100%',
            transition: '.5s ease-in-out',
            height: '150px',
            zIndex: -1,
            background:
              scrolled &&
              `linear-gradient(180deg, ${
                t.colors.primary && alpha('primary', 1)(t)
              } 0%,${t.colors.primary && alpha('primary', 1)(t)} 30%, ${
                t.colors.primary && alpha('primary', 0.1)(t)
              } 80%, rgba(255,255,255,0) 100%)`,
          },
        })}
      >
        <Nav />
      </div>
    </div>
  )
}

export default StickyBar
