/** @jsx jsx */
import { useAnalytics } from '@chordcommerce/gatsby-theme-autonomy'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { jsx } from 'theme-ui'
import { useLocation } from '@reach/router';
import { toURL } from '~/utils/intl/localePrefix';

const KitLink = ({ slug, children, sx, ...props }) => {
  const { trackClickProduct } = useAnalytics()
  const location = useLocation();
  
  const url = toURL(location && location.pathname, `/bundles/${slug}/`)

  return (
    <Link
      to={url}
      onClick={() => trackClickProduct(slug)}
      sx={{
        ...sx
      }}
      {...props}
    >
      {children}
    </Link>
  )
}

KitLink.propTypes = {
  slug: PropTypes.string.isRequired,
  children: PropTypes.node
}

export default KitLink
