import { graphql, useStaticQuery } from 'gatsby'

const useNavigationQuery = locale => {
  const data = useStaticQuery(graphql`
    {
      contentfulNavigationBar_enUS: contentfulNavigationBar(
        slug: { eq: "navigation-bar" }
        node_locale: { eq: "en-US" }
      ) {
        tabs {
          ...NavSectionFragment
          ... on ContentfulPage {
            id
            name
            slug
          }
          ... on ContentfulCollection {
            id
            name
            slug
          }
        }
      }

      contentfulNavigationBar_enCA: contentfulNavigationBar(
        slug: { eq: "navigation-bar" }
        node_locale: { eq: "en-CA" }
      ) {
        tabs {
          ...NavSectionFragment
          ... on ContentfulPage {
            id
            name
            slug
          }
          ... on ContentfulCollection {
            id
            name
            slug
          }
        }
      }

      contentfulNavigationBar_frCA: contentfulNavigationBar(
        slug: { eq: "navigation-bar" }
        node_locale: { eq: "fr-CA" }
      ) {
        tabs {
          ...NavSectionFragment
          ... on ContentfulPage {
            id
            name
            slug
          }
          ... on ContentfulCollection {
            id
            name
            slug
          }
        }
      }

      contentfulNavigationBar_heIL: contentfulNavigationBar(
        slug: { eq: "navigation-bar" }
        node_locale: { eq: "he-IL" }
      ) {
        tabs {
          ...NavSectionFragment
          ... on ContentfulPage {
            id
            name
            slug
          }
          ... on ContentfulCollection {
            id
            name
            slug
          }
        }
      }
    }
  `)
  const cleanedUpLocale = `${locale}`.split('-').join('')
  const defaultLocale = 'enUS'

  return (
    data[`contentfulNavigationBar_${cleanedUpLocale}`] ||
    data[`contentfulNavigationBar_${defaultLocale}`]
  )
}

export default useNavigationQuery
