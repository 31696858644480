/** @jsx jsx */
import PropTypes from 'prop-types'
import { Box, Flex, Text, Grid, jsx } from 'theme-ui'
import { useLocation } from '@reach/router'
import { toURL } from '~/utils/intl/localePrefix'
import Sublink from '~/components/Nav/NavMenu/Sublink'
const FooterLinks = ({ catalog, links }) => {
  const location = useLocation()
  return (
    <Flex
      sx={{
        borderBottom: [null, '1px solid', 'none'],
        flexDirection: ['row'],
        flexWrap: ['wrap', 'unset'],
        textAlign: ['left'],
        width: ['100%', null, '50%'],
        marginTop: 90,
      }}
    >
      <Box
        sx={{
          lineHeight: ['35px', 'inherit'],
          padding: ['1.5rem 0', null, '0'],
          width: ['50%', '100%'],
        }}
      >
        <Text
          variant="text.eyebrow.uppercase"
          as="div"
          sx={{ paddingBottom: [10, 20] }}
        >
          SHOP
        </Text>
        <Grid sx={{ gap: [15, 20], textTransform: 'capitalize' }}>
          {catalog.collections
            .filter(({ slug, hidden }) => slug !== 'kits' && !hidden)
            .map(item => (
              <Sublink
                forwardSx={{
                  fontFamily: 'heading',
                  fontSize: ['16px', '20px'],
                  lineHeight: ['18px', '24px'],
                }}
                key={item.id}
                slug={item.slug}
                contenttype={item.__typename}
              >
                {item.title}
              </Sublink>
            ))}
          <Text
            as="p"
            variant="text.body.large"
            sx={{
              fontFamily: 'Sofia',
              marginBottom: '0.25rem',
            }}
          >
            <Sublink
              forwardSx={{
                fontFamily: 'heading',
                fontSize: ['16px', '20px'],
                lineHeight: ['18px', '24px'],
              }}
              slug={toURL(location && location.pathname, `/shop/`)}
            >
              shop all
            </Sublink>
          </Text>
        </Grid>
      </Box>

      {links.map(navSection => {
        let { pages } = navSection
        return (
          <Box
            key={navSection.id}
            sx={{
              lineHeight: ['35px', 'inherit'],
              padding: ['1.5rem 0', null, '0'],
              width: ['50%', '100%'],
            }}
          >
            <Text
              variant="text.eyebrow.uppercase"
              as="div"
              sx={{ paddingBottom: [10, 30] }}
            >
              {navSection.navSectionName}
            </Text>

            <Grid sx={{ gap: [15, 20] }}>
              {pages.map(item => {
                return (
                  <Sublink
                    forwardSx={{
                      fontFamily: 'heading',
                      fontSize: ['16px', '20px'],
                      lineHeight: ['18px', '24px'],
                    }}
                    key={item.id}
                    contenttype={item.__typename}
                    slug={item.slug}
                  >
                    {item.name}
                  </Sublink>
                )
              })}
            </Grid>
          </Box>
        )
      })}
    </Flex>
  )
}

FooterLinks.propTypes = {
  catalog: PropTypes.shape({
    collections: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        slug: PropTypes.string,
      }),
    ),
  }),
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
    }),
  ),
}

export default FooterLinks
