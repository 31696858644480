/** @jsx jsx */

import { Flex, jsx } from 'theme-ui'
import Sublink from './Sublink'

const SectionPageMap = ({ pages, cta }) => {
  return (
    <Flex
      sx={{
        gap: '28px',
        flexDirection: 'column',
      }}
    >
      {pages.map(page => {
        if (/collection/i.test(page.__typename))
          return page.products.map(product => (
            <Sublink
              tagLine={product.tagLine}
              contenttype={'ContentfulProduct'}
              slug={product.slug}
            >
              {product.name}
            </Sublink>
          ))

        if (/product/i.test(page.__typename))
          return (
            <Sublink
              slug={page?.slug}
              contenttype={page.__typename}
              tagLine={page.tagLine}
            >
              {page.name}
            </Sublink>
          )
        if (/kit/i.test(page.__typename)) {
          return (
            <Sublink
              slug={page.slug}
              contenttype={page.__typename}
              tagLine={page?.kitProductDisplayReference?.tagLine}
            >
              {page.name}
            </Sublink>
          )
        }

        if (/page/i.test(page.__typename))
          return (
            <Sublink
              tagLine={page.tagLine}
              slug={page.slug}
              contenttype={page.__typename}
            >
              {page.name}
            </Sublink>
          )
      })}
      {cta && (
        <Sublink
          secondary
          slug={cta.page?.slug ?? cta.externalLink}
          contenttype={cta?.page?.__typename}
        >
          {cta.text}
        </Sublink>
      )}
    </Flex>
  )
}

export default SectionPageMap
