import { useCart } from '@chordcommerce/gatsby-theme-autonomy'
import { useEffect, useState } from 'react'

export function useAddToCart() {
  const { cart, addToCart: chordAddToCart, subscribeProduct: chordSub } = useCart()
  const [addedSku, setAddedSku] = useState(null)
  const addToCart = async (sku, quantity = 1, animate = true) => {
    await chordAddToCart(sku, quantity)
    if (animate) {
      setAddedSku(sku)
    }
  }

  const subscribeProduct = async (args, animate = true) => {
    await chordSub(args)
    if (animate) {
      setAddedSku(args.sku);
    }
  }

  useEffect(() => {
    if (addedSku) {
      const item = (cart?.data?.lineItems || []).find(
        item => item?.variant?.sku === addedSku
      )
      if (item) {
        const event = new CustomEvent('addToCart', { detail: item })
        document.dispatchEvent(event)
        setAddedSku(null)
      }
    }
  }, [cart, addedSku])

  return { addToCart, subscribeProduct }
}
