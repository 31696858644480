/** @jsx jsx */
import { Flex, jsx } from 'theme-ui'

import NavTabDropdown from './NavTabDropdown'
import NavMenuLink from './Link'

const NavSectionDesktop = ({
  tabs,
  handleNavSectionChange,
  currentFocus,
  open,
}) => {
  return (
    <Flex
      sx={{
        display: ['none', 'flex'],
        flexDirection: ['row'],
        justifyContent: 'center',
        width: '100%',
        gap: '32px',
        zIndex: 10,
        position: ['unset'],
        top: '75%',
        opacity: open ? '1' : ['0', '1'],
        pointerEvents: open ? 'all' : ['none', 'all'],
        transition: 'opacity 200ms 250ms',
      }}
    >
      {tabs.map(tab => {
        if (/contentfulpage/i.test(tab.__typename)) {
          return (
            <NavMenuLink
              page={tab}
              key={tab.id}
              forwardSx={{ opacity: open ? 0.5 : 1 }}
            />
          )
        }
        if (/contentfulnavsection/i.test(tab.__typename)) {
          return (
            <NavTabDropdown
              key={tab.id}
              open={open}
              tab={tab}
              currentFocus={currentFocus}
              handleNavSectionChange={() => handleNavSectionChange(tab)}
            >
              {tab.navSectionName}
            </NavTabDropdown>
          )
        }
        if (/contentfulcollection/i.test(tab.__typename)) {
          return (
            <NavMenuLink
              page={tab}
              key={tab.id}
              forwardSx={{ opacity: open ? 0.5 : 1 }}
            />
          )
        }

        return null
      })}
    </Flex>
  )
}

export default NavSectionDesktop
