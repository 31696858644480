/** @jsx jsx */
import { Text, Box, jsx } from 'theme-ui'

const NavTabDropdown = ({
  children,
  tab,
  handleNavSectionChange,
  currentFocus,
  open
}) => {
  return (
    <Box key={tab.id} sx={{ textAlign: 'center', width: 'fit-content' }}>
      <Text
        className="tab-dropdown"
        sx={{
          cursor: 'pointer',
          whiteSpace: 'nowrap',
          fontSize: ['22px', null, '20px'],
          fontFamily: 'heading',
          opacity: ['1', open ? (currentFocus === tab.id ? 1 : 0.5) : 1]
        }}
        data-open={'false'}
        data-navsection={tab.navSectionName.replace(/ /g, '')}
        onClick={handleNavSectionChange}
      >
        {children}
        <Box sx={{ display: 'inline-block', marginLeft: '5px' }}>
          <svg
            style={{
              transform:
                currentFocus === tab.id ? 'rotate(180deg)' : 'rotate(0deg)'
            }}
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.01018 6L10 0.863219L9.14172 -7.50335e-08L4.99002 4.27397L0.838324 -8.00939e-07L4.49072e-07 0.863218L4.98982 6L5.01018 6Z"
              sx={{ fill: open ? 'governorBay' : 'secondary' }}
            />
          </svg>
          {/* carrot  */}
        </Box>
      </Text>
    </Box>
  )
}

export default NavTabDropdown
