/** @jsx jsx */
import PropTypes from 'prop-types'
import React, { useState, useContext } from 'react'
import { Flex, jsx } from 'theme-ui'
import { shallowEqual, useSelector } from 'react-redux'
import Footer from '~/components/Footer'
import { CartProvider } from '../Mini-Cart/CartContext'
import YotpoScript from '../Reviews/Script/YotpoScript'
import { DuneBuggy } from './DuneBuggy'
import { useWindowScroll } from 'react-use'
import NotificationBar from '../Notification/Bar'
import { AddedToCartNotification } from '../Notification/AddedToCart/AddedToCart'
import { DkCartWrapper } from '../DailyKarma/DKCartWrapper'
import { useEffect } from 'react'
import { localeFromPathname } from '~/utils/intl/localePrefix'
import { useCart } from '@chordcommerce/gatsby-theme-autonomy'
import StickyBar from './StickyBar'
import MessageSnackbar from './MessageSnackbar'
import MessageContext from '~/contexts/MessageContext'

const LayoutScroll = ({ children, navBackgroundColor }) => {
  const { y } = useWindowScroll()
  const [buggyEnabled, setBuggyEnabled] = useState(false)
  const { modifyCart } = useCart()
  const [message] = useContext(MessageContext)

  const cart = useSelector(state => state.cart.data, shallowEqual)

  useEffect(() => {
    const updateMetadata = async () => {
      if (!cart || typeof window === 'undefined') {
        return
      }
      const currentLocale = localeFromPathname(window.location.pathname)
      const expectedMetadata = {
        currency: 'USD' || currentLocale.currency,
        locale: currentLocale.code,
      }

      let shouldUpdate = Object.keys(expectedMetadata).find(
        key =>
          !cart.metadata ||
          !cart.metadata[key] ||
          cart.metadata[key] !== expectedMetadata[key],
      )
      if (shouldUpdate) {
        modifyCart({
          metadata: {
            ...(cart.metadata || {}),
            ...expectedMetadata,
          },
        })
      }
    }

    updateMetadata()
  }, [cart])

  useState(() => {
    const timeout = setTimeout(() => {
      setBuggyEnabled(true)
    }, 2000)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  const buggyInDOM =
    typeof window !== 'undefined' &&
    buggyEnabled &&
    !!window &&
    y + 400 > window.document.body.scrollHeight - window.innerHeight

  const renderBuggy =
    typeof window !== 'undefined'
      ? y + 50 > window.document.body.scrollHeight - window.innerHeight
      : 0

  const isCart =
    typeof window !== 'undefined' && window.location.pathname.includes('cart')

  return (
    <>
      <CartProvider>
        <MessageSnackbar message={message} />
        <DkCartWrapper />
        <YotpoScript />
        <Flex
          sx={{
            minHeight: '100vh',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          {buggyInDOM && <DuneBuggy shouldRender={renderBuggy} />}
          {/* Get size without doing wacky JS */}
          <div sx={{ visibility: 'hidden' }} aria-hidden="true">
            <NotificationBar />
            <div sx={{ height: '80px' }}></div>
          </div>

          <StickyBar background={navBackgroundColor && navBackgroundColor} />

          <Flex sx={{ flex: 1, flexDirection: 'column', position: 'relative' }}>
            {children}
          </Flex>

          {!isCart && <Footer />}
        </Flex>
        <AddedToCartNotification />
      </CartProvider>
    </>
  )
}

LayoutScroll.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutScroll
