/** @jsx jsx */
import { Box, jsx } from 'theme-ui'
import { keyframes } from '@emotion/react'

const floatAnimObj = (distance, duration) => {
  const float = keyframes`
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, -${distance}px); }
    100%   { transform: translate(0, -0px); }
    `
  return {
    animation: `${float} ${duration}ms infinite ease-in-out`
  }
}

const driveAnimObj = duration => {
  const drive = keyframes`
    0% { 
      visibility: unset;
      right: -300;
      bottom:100%;
      transform:translateX(100%) rotate(0deg);
         }
    100%  { 
            visibility: unset;

      right:100%;
      transform:translateX(0%) rotate(-5deg);
 }
    `
  return {
    animation: `${drive} ${duration}ms  infinite linear`
  }
}
export function DuneBuggy({ shouldRender }) {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: '50%',
        right: 0,
        width: '100%',
        aspectRatio: '2.1 / 1',
        transform: 'translateY(-50%)',
        pointerEvents: 'none'
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          width: 300,
          bottom: '0',
          right: -300,
          ...(shouldRender ? driveAnimObj(3000) : {}),
          animationIterationCount: 1
        }}
      >
        <img sx={{ width: '100%', ...floatAnimObj(5, 350) }} src="/buggy.png" />
      </Box>
    </Box>
  )
}
