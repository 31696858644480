/** @jsx jsx */

import { useWindowSize } from 'react-use'
import { jsx, Grid } from 'theme-ui'

export function SectionDivider({ sx, forwardSx, fill, small = false }) {
  let uuid = Math.random()
  const { width } = useWindowSize()
  const centerMagicNumber = 706
  return (
    <Grid
      sx={{
        pointerEvents: 'none',

        ...forwardSx,
      }}
    >
      {!small ? (
        <svg
          shapeRendering="geometricPrecision"
          sx={{
            marginTop: '-10px',
            ...sx,
            transform: 'translateY(-2px)',
          }}
          strokeWidth={0}
          width="100%"
          height="191"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <pattern
              x={width / 2 - centerMagicNumber}
              id={'bg' + uuid}
              overflow="visible"
              patternUnits="userSpaceOnUse"
              width="1321"
              height="192"
              viewBox="0 0 1321 191"
              // sx={{ transform: 'translateY(1px)' }}
            >
              <path
                d="M0.280273 40.6195C19.6115 29.0991 49.5749 15.452 87.9151 14.7431C153.641 13.6797 178.127 71.1041 252.875 75.3577C326.011 79.4342 341.798 28.0357 415.579 23.9593C545.098 17.0471 581.828 187.016 706.836 190.207C846.343 193.751 903.048 1.09592 1027.73 13.8569C1119.56 23.2504 1149.52 93.4359 1227.81 90.6001C1270.34 89.005 1300.63 63.8374 1320.28 40.6195C1320.28 22.8736 1320.28 10.9266 1320.28 0.754883L660.3 0.754883L0.3 0.754883L0.3 40.6"
                fill={fill}
                stroke={fill}
                strokeWidth="3"
              />
            </pattern>
          </defs>
          <rect width="100%" height="100%" fill={`url(#bg${+uuid})`} />
        </svg>
      ) : (
        <svg
          sx={{
            ...sx,
            transform: 'translateY(3px)',
          }}
          width="100%"
          height="73"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <pattern
              id={'bg' + uuid}
              overflow="visible"
              sx={{ transform: 'translateY(-1px)' }}
              strokeWidth="1.5"
              patternUnits="userSpaceOnUse"
              width="2878"
              height="73"
              viewBox="0 0 2878 73"
            >
              <mask
                id={'mask0' + uuid}
                style={{ maskType: 'alpha' }}
                width="1496"
                height="73"
                x="1382"
                y="0"
                maskUnits="userSpaceOnUse"
              >
                <path
                  fill={fill}
                  d="M2878 73H4374V146H2878z"
                  transform="rotate(-180 2878 73)"
                ></path>
              </mask>
              <g mask={`url(#mask0${uuid})`}>
                <path
                  fill={fill}
                  d="M2639.65 68.03c90.13 14.272 135.85-112.368 228.14-52.011C2931.67 57.897 2889.82 130.557 2855 196H1441.03c-12.5-51.769-137.57-123.253-50.31-179.981 77.77-50.68 138 41.004 203.7 52.01 54.19 8.951 80.07-28.908 108.25-44.51 22.13-12.096 49.45-17.781 86.39-17.297 80.5 1.088 101.35 74.992 180.41 61.808 64.4-10.765 86.11-77.774 179.12-61.808 20.56 3.507 39.96 8.104 55.49 16.208 31.34 16.328 55.34 55.034 114.71 45.6 64.98-10.282 86.4-77.774 179.12-61.808 68 11.732 85.25 52.857 141.74 61.808z"
                ></path>
              </g>
              <mask
                id={'mask1' + uuid}
                style={{ maskType: 'alpha' }}
                width="1496"
                height="74"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
              >
                <path
                  fill={fill}
                  d="M0 0H1496V73H0z"
                  transform="matrix(1 0 0 -1 0 73)"
                ></path>
              </mask>
              <g mask={`url(#mask1${uuid}})`}>
                <path
                  fill={fill}
                  d="M238.349 68.03C148.215 82.302 102.502-40.338 10.212 16.019-53.675 57.897-11.818 130.557 22.996 196H1436.98c12.5-51.769 137.57-123.253 50.31-179.981-77.77-50.68-138 41.004-203.7 52.01-54.19 8.951-80.07-28.908-108.25-44.51-22.13-12.096-49.45-17.781-86.39-17.297-80.5 1.088-101.348 74.992-180.412 61.808-64.402-10.765-86.109-77.774-179.117-61.808-20.557 3.507-39.964 8.104-55.489 16.208-31.338 16.329-55.345 55.034-114.715 45.6C494.23 57.748 472.811-9.744 380.09 6.222c-67.996 11.732-85.246 52.857-141.741 61.808z"
                ></path>
              </g>
            </pattern>
          </defs>
          <rect width="100%" height="100%" fill={`url(#bg${uuid})`} />
        </svg>
      )}
    </Grid>
  )
}
