const intStrings = [
  'zero',
  'one',
  'two',
  'three',
  'four',
  'five',
  'six',
  'seven',
  'eight',
  'nine'
]

export const intToWord = num => num >= intStrings.length ? `${num}` : intStrings[num]
