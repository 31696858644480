/** @jsx jsx */
import { useEffect, useRef, useContext } from 'react'
import { Flex, Text, jsx } from 'theme-ui'
import { gsap } from 'gsap'
import MessageContext from '~/contexts/MessageContext'

const MessageSnackbar = ({ message }) => {
  const [, setMessage] = useContext(MessageContext)
  const snackbarRef = useRef()
  useEffect(() => {
    if (message) {
      gsap.to(snackbarRef.current, { bottom: 25, opacity: 1, duration: 0.5 })
      gsap.to(snackbarRef.current, {
        bottom: 0,
        opacity: 0,
        duration: 0.5,
        delay: 3,
      })
    }
    setTimeout(() => {
      setMessage('')
    }, 3500)
  }, [message])
  return message ? (
    <Flex
      ref={snackbarRef}
      sx={{
        position: 'fixed',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 500000,
        bottom: 0,
        opacity: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        borderRadius: '5px',
        px: '25px',
        py: '10px',
        backgroundColor: 'red',
        color: 'schoolBusYellow',
      }}
    >
      <Text>{message}</Text>
    </Flex>
  ) : null
}

export default MessageSnackbar
