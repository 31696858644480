import React, { useEffect } from 'react';
import { useCart } from '@chordcommerce/gatsby-theme-autonomy';
import { useAddToCart } from '../../hooks/utils/useAddToCart';

export const DkCartWrapper = () => {
  const { addToCart } = useAddToCart();
  const { cart } = useCart()

  useEffect(() => {
    window.dkAddToCart = async (sku, quantity, animate = true) => {
      try {
        await addToCart(sku, quantity, animate)
      } catch (e) {
        console.error(e)
      }
    }
    window.dkGetCartInfo = () => {
      return { number: cart?.data?.number, token: cart?.data?.token }
    }

    return () => {
      window.dkAddToCart = null
      window.dkGetCartInfo = null
    }
  }, [addToCart, cart])

  return <></>
}