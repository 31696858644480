/** @jsx jsx */
import { Link } from 'gatsby'
import { jsx } from 'theme-ui'
import CollectionLink from '~/components/Collection/Link'
import KitLink from '~/components/Kit/Link'
import ProductLink from '~/components/Product/Link'
import { useLocation } from '@reach/router'
import { toURL } from '~/utils/intl/localePrefix'

// import React from 'react'
export function LinkWrapper(props) {
  const location = useLocation()
  let Component
  switch (props.contenttype) {
    case 'ContentfulCollection':
      Component = CollectionLink
      break
    case 'ContentfulKit':
      Component = KitLink
      break
    case 'ContentfulProduct':
      Component = ProductLink
      break
    case 'ContentfulPage':
      // handled by ternary below

      break
    default:
      break
  }

  if (!props.contenttype) return null

  return props.contenttype === 'ContentfulPage' ? (
    <Link {...props} to={toURL(location && location.pathname, props.slug)}>
      {props.children}
    </Link>
  ) : (
    <Component {...props}>{props.children}</Component>
  )
}
