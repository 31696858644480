import { graphql, useStaticQuery } from 'gatsby'

const useFooterQuery = () => {
  const data = useStaticQuery(
    graphql`
      query FooterQuery($locale: String) {
        catalog: contentfulCatalog(
          slug: { eq: "catalog" }
          node_locale: { eq: $locale }
        ) {
          collections {
            __typename
            id
            title
            slug
            hidden
          }
        }
        links: contentfulNavigationBar(
          slug: { eq: "footer-links" }
          node_locale: { eq: $locale }
        ) {
          tabs {
            ...NavSectionFragment
            ... on ContentfulPage {
              __typename
              id
              name
              slug
            }
          }
        }
        social: contentfulSocialLinks {
          facebookUsername
          instagramUsername
          tiktokUsername
          twitterUsername
          pinterestUsername
          linkedinUsername
          spotifyUsername
        }
        legal: contentfulNavigationBar(
          slug: { eq: "footer-legal" }
          node_locale: { eq: $locale }
        ) {
          tabs {
            ... on ContentfulNavSection {
              id
              navSectionName
              pages {
                ... on ContentfulPage {
                  __typename
                  id
                  name
                  slug
                }
                ... on ContentfulCollection {
                  __typename
                  id
                  name
                  slug
                }
              }
            }
            ... on ContentfulPage {
              __typename
              id
              name
              slug
            }
          }
        }
      }
    `,
  )

  return data
}

export default useFooterQuery
