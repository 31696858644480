import React, { useState, useEffect, useContext } from 'react'
import LayoutScroll from './LayoutScroll'
import PropTypes from 'prop-types'

const Layout = ({ children, navBackgroundColor }) => {
  const [hasLoaded, setHasLoaded] = useState(false)
  useEffect(() => {
    if (!hasLoaded && typeof window !== 'undefined') {
      const script = document.createElement('script')
      script.src = 'https://assets.dailykarma.io/prod/chord/init.js'
      document.head.appendChild(script)
      setHasLoaded(true)
    }
  }, [])
  return (
    <LayoutScroll navBackgroundColor={navBackgroundColor}>
      {children}
    </LayoutScroll>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
