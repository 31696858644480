/** @jsx jsx */
import Img from 'gatsby-image'

import { useEffect, useState, useRef, Fragment } from 'react'
import { Flex, jsx } from 'theme-ui'

import Spinner from '../Spinner'

const ImgLoader = ({ slug, image, forwardSx, imgStyle, alt }) => {
  const [color, setColor] = useState('none')
  const [spinner, setSpinner] = useState(true)
  const [height, setHeight] = useState()
  const [width, setWidth] = useState()
  const imgRef = useRef(null)

  const getColor = slug => {
    switch (slug) {
      case 'cheddy-mac':
        setColor('#00E0BE')
        break
      case 'variety-pack':
        setColor('#F752AD')
        break
      case 'mover-shaker':
        setColor('#6027B4')
        break
      case 'shella-good':
        setColor('#FF5947')
        break
      case 'twist-parm':
        setColor('#45EEFF')
        break
      default:
        setColor('none')
    }
  }

  useEffect(() => {
    getColor(slug)
    setHeight(imgRef?.current?.imageRef?.current?.clientHeight)
    setWidth(imgRef?.current?.imageRef?.current?.clientWidth)
  }, [imgRef.current])

  return (
    <Fragment>
      {(() => {
        if (height && width && spinner)
          return (
            <Flex
              sx={{
                overflow: 'hidden',
                position: 'absolute',
                height: `${height}px`,
                width: `${width}px`,
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1,
                transition: spinner && '.3s ease',
                opacity: spinner ? 1 : 0
              }}
            >
              <Spinner size="150" type={slug} />
            </Flex>
          )
      })()}
      {(() => {
        if (color && image && image.fluid)
          return (
            <Img
              ref={imgRef}
              fluid={image.fluid}
              alt={alt || image.title}
              key={image.title}
              onStartLoad={() => setSpinner(true)}
              onLoad={() => setSpinner(false)}
              sx={{
                background: color,
                ...forwardSx
              }}
              imgStyle={imgStyle}
            ></Img>
          )
      })()}
    </Fragment>
  )
}

export default ImgLoader
