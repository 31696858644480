import { graphql, useStaticQuery } from 'gatsby'

const useNotificationBarQuery = (locale) => {
  const data = useStaticQuery(graphql`
    {
      notificationBar_enUS: contentfulNotificationBar(
        slug: { eq: "notification-bar" }, node_locale: { eq: "en-US" }
      ) {
        notification {
          slug
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
      referralNotification_enUS: contentfulNotification(slug: { eq: "referral" }, node_locale: { eq: "en-US" }) {
        slug
        description {
          childMarkdownRemark {
            html
          }
        }
      }

      notificationBar_enCA: contentfulNotificationBar(
        slug: { eq: "notification-bar" }, node_locale: { eq: "en-CA" }
      ) {
        notification {
          slug
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
      referralNotification_enCA: contentfulNotification(slug: { eq: "referral" }, node_locale: { eq: "en-CA" }) {
        slug
        description {
          childMarkdownRemark {
            html
          }
        }
      }

      notificationBar_frCA: contentfulNotificationBar(
        slug: { eq: "notification-bar" }, node_locale: { eq: "fr-CA" }
      ) {
        notification {
          slug
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
      referralNotification_frCA: contentfulNotification(slug: { eq: "referral" }, node_locale: { eq: "fr-CA" }) {
        slug
        description {
          childMarkdownRemark {
            html
          }
        }
      }

      notificationBar_heIL: contentfulNotificationBar(
        slug: { eq: "notification-bar" }, node_locale: { eq: "he-IL" }
      ) {
        notification {
          slug
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
      referralNotification_heIL: contentfulNotification(slug: { eq: "referral" }, node_locale: { eq: "he-IL" }) {
        slug
        description {
          childMarkdownRemark {
            html
          }
        }
      }
    }

  `)
  const cleanedUpLocale = `${locale}`.split('-').join('');
  const defaultLocale = 'enUS';
  const finalObject = {
    notificationBar: data[`notificationBar_${cleanedUpLocale}`] || data[`notificationBar_${defaultLocale}`],
    referralNotification: data[`referralNotification_${cleanedUpLocale}`] || data[`referralNotification_${defaultLocale}`]
  };
  return finalObject
}

export default useNotificationBarQuery
